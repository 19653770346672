@tailwind base;
@tailwind components;
@tailwind utilities;

/* "Fix" dark/light mode toggle */

.react-toggle-thumb {
  margin-top: -0.275rem; /* Adjust vertically as needed */
  margin-left: 0.1rem; /* Adjust vertically as needed */
}

.react-toggle-track {
  background-color: transparent;
}

.react-toggle--checked .react-toggle-track {
  background-color: transparent;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: transparent;
}

.dark-mode .react-toggle--checked .react-toggle-track {
  background-color: transparent;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: transparent;
}
